import React from 'react';

class MyWebPage extends React.Component {
  render() {
    return (
      <body>
        <div class='container width'>
          <h1>Lab10c - Eric Wang</h1>
          <div class='container'>
            <h3>Installation</h3>
            <p>
              To utilize React.js with Visual Studio Code I did the following steps:
            </p>
            <ol>
              <li>
                <b>Install Node.js: </b>
                React.js needs Node.js to be installed for it to work. I installed Node.js from this website:
                <a href='https://nodejs.org/en/download/' target='_blank' rel='noopener noreferrer'> Node.js</a>
              </li>
              <li>
                <b>Create a new React application: </b>
                Use the following command in the VSCode terminal:
                <code> npx create-react-app lab10c</code>
              </li>
              <li>
                <b>Navigate to the App Directory: </b>
                Move into the App directory:
                <code> cd lab10c</code>
              </li>
              <li>
                <b>Run the App: </b>
                Start the development server and open the app in my default browser: 
                <code> npm start</code>
              </li>
            </ol>
          </div>
    
          <div class='container'>
            <h3>Building the Page</h3>
            <p>This wasn't too difficult and was pretty straightforward. I had to do the following steps:</p>
            <ol>
              <li>
                <b>Create the page with HTML code: </b>
                Under the <code>src</code> folder, I added my HTML code into App.js. This consisted of basic HTML functions
                such as headers, divs, and ordered lists
              </li>
              <li>
                <b>Add CSS styling to the page: </b>
                Added CSS styling inside the index.css file
              </li>
              <li>
                <b>Host the page: </b>
                I followed the following guide to 
                <a href='https://github.com/gitname/react-gh-pages'> host the site on GitHub</a>
              </li>
            </ol>
          </div>

          <div class='container'>
          <h3>Difficulties Encountered</h3>
          <p>I found it a bit confusing when installing Node.js. I had a problem where the PATH wasn't correct and I couldn't 
              run the command to install create react app. I had to go through my environment variables and fix this issue.
              Other than that the rest was pretty straightforward.</p>
          </div>   
        </div>
      </body>
    );
  }
}

export default MyWebPage;

